/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const Layout: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <header className="bg-gray-100 text-center mx-auto p-6">
        <h1 className="text-2xl font-medium mt-0 md:mt-6 mb-4">
          <Link to={`/`} className="text-black hover:text-black">
            {data.site.siteMetadata.title}
          </Link>
        </h1>
        <nav>
          <ul className="flex justify-center list-none m-0">
            <li className="mx-4">
              <Link to={`/archives/`}>
                archives
              </Link>
            </li>
            <li className="mx-4">
              <Link to={`/index.xml`}>
                rss
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <main className="container max-w-screen-lg mx-auto px-4 sm:px-4 md:px-8 lg:px-20 xl:px-0 mt-10 mb-20">
        {children}
      </main>
    </>
  )
}

export default Layout
